.h1 {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.25rem;
}

.h2 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.h3 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
}

.h4 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

.h5 {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.h6 {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
}


.Subheading-1 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.Subheading-2 {
  font-size: 1rem;
  line-height: 1.5rem;
}

.Subheading-3 {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.Subheading-4 {
  font-size: 0.75rem;
  line-height: 1rem;
}

.Subtitle-Large {
  font-size: 1rem;
  line-height: 1.5rem;
}

.Subtitle-Default {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.Subtitle-Small {
  font-size: 0.75rem;
  line-height: 1rem;
}

.Body-Large {
  font-size: 1rem;
  line-height: 1.5rem;
}

.Body-Default {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.Body-Small {
  font-size: 0.75rem;
  line-height: 1rem;
}

.Caption-Small {
  font-size: 0.75rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}

.Caption-Extra-Small {
  font-size: 0.625rem;
  line-height: .75rem;
  text-transform: uppercase;
}

canvas {
  width: 100%;
}

.rotate-180 {

  transform: rotate(180deg)
}


.cursor-pointer {
  cursor: pointer;
}

.bg-light-primary {
  background: #ebeffe;
}

.content-input-width {
  width: 83%;
}

.content-label-width {
  width: 17%;
}

.bg-disabled {
  color: #d4d4d8;
}

.rotate-verticle {
  transform: rotate(270deg);
  display: inline-block;
}

.skeleton {
  background-image: linear-gradient(90deg,
      #e2e2e2 0px,
      #efefef 30px,
      #e2e2e2 60px);
  background-size: calc(91px + 91px);
  animation: refresh 1.2s infinite ease-out;
}

.skeleton-quote {
  background-image: linear-gradient(90deg,
      #e2e2e2 0px,
      #efefef 30px,
      #e2e2e2 60px);
  background-size: calc(209px + 209px);
  animation: refresh-quote 1.2s infinite ease-out;
}

@keyframes refresh-quote {
  0% {
    background-position: calc(-208px);
  }

  60%,
  100% {
    background-position: 209px;
  }
}

@keyframes refresh {
  0% {
    background-position: calc(-91px);
  }

  60%,
  100% {
    background-position: 91px;
  }
}

.main-content {
  min-height: 100vh;
}

.h-100vh-60px {
  min-height: calc(100vh - 60px);
}

.sr-only {
  display: none;
}

.card-scleton {
  border-bottom: 3px solid #f8f8fb;
  padding-top: 13px;
  font-weight: 600;
  font-size: 24px;
  min-height: 45px;
}

.card-heading {
  padding-left: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #26273d;
}

.card-heading-inner {
  color: #26273d;
  font-weight: 500;
  font-size: 13px;
}

.assign-card {
  background-color: rgb(248, 248, 251);
  border: 1px solid rgb(212, 212, 216);
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.booking-Details {
  color: #26273d;
  line-height: 20px;
  font-style: normal;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-image {
  height: 60px;
  width: 60px;
  margin-right: 10px;
}

.user-name {
  font-weight: 600;
  font-size: 13px;
}

.button {
  background-color: rgb(85, 110, 230);
  border-radius: 4px;
  color: white;
  cursor: pointer;
  padding: 10px 15px;
  display: inline-block;
  white-space: pre;
}

.booking-status-dropdown {
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
  box-sizing: border-box;
  height: 50px;
  background-color: rgb(255, 255, 255) !important;
}

.mt-10 {
  margin-top: 10px;
}

.address-place {
  background-color: rgb(230, 230, 230);
  margin-top: 20px;
  padding: 1px 6px;
  border-radius: 5px;
  font-size: 12px;
}

.partner-icon {
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
  border-radius: 5px;
  align-items: center;
}

.assign-parent {
  font-style: normal;
  line-height: 20px;
}

.pad-mar {
  padding: 0px !important;
  margin: 0px !important;
  background-color: #fff;
}

.assign-left-heading {
  padding: 24px 16px;
  background-color: #ebeffe;
}

.assign-left-heading-sub {
  font-weight: 500;
  font-size: 13px;
}

.assign-time {
  font-weight: 400;
  font-size: 13px;
}

.assign-data-selector {
  padding: 20px;
}

.assign-data-heading {
  font-size: 13px;
  font-weight: 500;
  margin-top: 10px;
}

.assign-input {
  width: 100%;
  height: 36px;
  border: 1px solid #d4d4d8;
  border-radius: 4px;
  padding: 8px, 12px !important;
  margin-top: 10px;
}

.assign-status-dropdown {
  width: 100%;
  /* margin-top: 10px; */
  border-radius: 4px;
  box-sizing: border-box;
  height: 36px;
  border: 1px solid #d4d4d8;
  padding: 8px 12px;
  background-color: rgb(255, 255, 255) !important;
}

.assign-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 8px 16px; */
}

.assign-search {
  padding: 12px 16px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

.assign-search-bar {
  border: 1px solid #dde3ee;
  border-radius: 30px;
  padding-left: 37px;
  color: #74788d;
}

.assign-time-sloat {
  padding: 10px 15px;
  height: 100%;
  overflow: hidden;
}

.time-slot {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  display: inline-flex;
}

.time-sloat-assign {
  font-weight: 500;
  font-size: 13px;
  height: 100%;
}

.user-row {
  border-right: 1px solid #d4d4d8;
}

.user-row-border-non {
  /* border-right: 1px solid #D4D4D8; */
  height: 100%;
}


.chip {
  border-radius: 2px !important;
  background-color: #e6e6e6 !important;
  color: #495057 !important;
}

.paragraph {
  margin-top: 25px;
  padding-bottom: 10px;
  font-size: 14px;
  border-bottom: dashed 1px #ccc;
}

.paragraph h2 {
  font-size: 20px;
}

.paragraph h3 {
  font-size: 18px;
}

/* .paragraph ul {
margin: 0px;
padding: 0px;
} */
.paragraph ul li {
  position: relative;
  padding-bottom: 5px;
}

/* .paragraph ul li::before {
  content: ';
  display: inline-block;
  position: absolute;
  left: 0;
  top: 8;
width: 8;  
  }
  */
.partner-disable {
  cursor: not-allowed;
}

.partner-not-disable {
  cursor: pointer;
}

.partner-not-selected {
  margin-top: 10px;
  padding: 8px 16px;
  width: 100%;
  display: flex;
  justify-content: start;
  border: 1px solid rgb(212, 212, 216);
  border-radius: 4px;
}

.partner-selected {
  background: #ebeffe;
  border: 1px solid #556ee6;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 10px;
  padding: 8px 16px;
  width: 100%;
  display: flex;
  justify-content: start;
}

.cancle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  color: #495057 !important;
  border: none;
  background: #e6e6e6;
  border-radius: 4px;
}

.cancle:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  color: #495057 !important;
  border: none;
  background: #e6e6e6;
  border-radius: 4px;
}

.assign {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border: none;
  background: #556ee6;
  border-radius: 4px;
  color: white;
}

.assign:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border: none;
  background: #556ee6;
  border-radius: 4px;
}

.dialogbox-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  width: 500px;
  min-height: 300px;
  /* background:#fff; */
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.partner-role {
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #74788d;
}

.partner-scedule-card {
  background-color: rgb(248, 248, 251);
  border: 1px solid rgb(212, 212, 216);
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.partner-clock {
  display: flex;
  align-items: center;
}

.overflow-hidden {
  overflow: hidden;
}

.modal-window-scrool-none {
  overflow: hidden;
}

.reset {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  color: #495057 !important;
  border: none;
  background: #e6e6e6;
  border-radius: 4px;
  width: 100px;
}

.apply {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  color: #556ee6 !important;
  border: 1px solid #556ee6;
  background: #f8f8fb;
  border-radius: 4px;
  width: 100px;
}

.react-drawer-drawer {
  width: 400px !important;
  scroll-behavior: smooth;
  background: #f8f8fb !important;
}

.filter-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.pac-container.pac-logo {
  z-index: 9999;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.gpsSearch input {
  border: 1px solid #D4D4D8 !important;
  border-radius: 4px !important;
  margin-bottom: 12px;
}

.w-full {
  width: 100%;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-full {
  z-index: 99999;
}

.l-1 {
  left: 1px;
}

.l-2 {
  left: 2px;
}

.l-3 {
  left: 3px;
}

.l-4 {
  left: 4px;
}

.l-5 {
  left: 5px;
}

.r-1 {
  right: 1px;
}

.r-2 {
  right: 2px;
}

.r-3 {
  right: 3px;
}

.r-4 {
  right: 4px;
}

.r-5 {
  right: 5px;
}

.t-1 {
  top: 1px;
}

.t-2 {
  top: 2px;
}

.t-3 {
  top: 3px;
}

.t-4 {
  top: 4px;
}

.t-5 {
  top: 5px;
}

.b-1 {
  bottom: 1px;
}

.b-2 {
  bottom: 2px;
}

.b-3 {
  bottom: 3px;
}

.b-4 {
  bottom: 4px;
}

.b-5 {
  bottom: 5px;
}

.bg-white {
  background: #fff;
}

.bookingSearchBoxWidth {
  width: 380px !important;
}

.text-yellow-light {
  color: rgba(241, 180, 76, 0.18);
}

.text-yellow-main {
  color: #F1B44C;
}

.bg-yellow-light {
  background-color: rgba(241, 180, 76, 0.18)
}

.text-red-main {
  color: #F46A6A;
}

.bg-red-main {
  background-color: #F46A6A;
}

.bg-green-main {
  background-color: #34C38F;
}

.text-green-main {
  color: #34C38F;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.overflow-x-auto {
  overflow-x: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.allReview-lists:not(:last-child) {
  border-bottom: solid 1px #E6E6E6;
  padding-bottom: 16px;
  margin-bottom: 16px
}

.bg-surface-10 {
  background-color: #E6E6E6;
}

.ml-2 {
  margin-left: 1rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.pop-title {
  color: #26273D;
  font-size: 13px;
  font-weight: 500;
  margin: 0.25rem 0 0.75rem;
}

.pop-body {
  position: absolute;
  top: 1.75rem;
  left: -2rem;
  min-width: 12rem;
  -webkit-box-shadow: 0px 16px 48px 0px #0000002e;
  box-shadow: 0px 1px 53px 0px #0000002e;
  border-radius: 10px;
  padding: 1rem;
  background: #fff;
  z-index: 19;
}

.pop-body:before {
  content: '';
  width: 0;
  height: 0;
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
  position: absolute;
  top: -9px;
  left: 1.75rem;
}

.pop-body p {
  margin-bottom: 0;
}

.max-width-400 {
  max-width: 400px;
}

.table-responsive {
  min-height: 13rem;
}

.-mt-2 {
  margin-top: -4px;
  display: block;
}

.tooltip-cts {
  z-index: 10005;
  background-color: #fff;
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.18);
  padding: 1rem;
  border-radius: 5px;
  font-size: 13px;
  color: #495057;
  top: 1.5rem;
  right: 1.25rem;
  max-width: 25rem;
}

.cst-card .row.react-bootstrap-table-pagination {
  margin: 0;
}

.small-label {
  font-size: 14px;
}

input.form-check-input.small {
  width: 1rem;
  height: 1rem;
  border-radius: 3px;
}

.h4.card-title:empty,
p.card-title-desc:empty {
  display: none;
}

.with-arrow button:after {
  content: '\2304';
  font-size: 18px;
  display: inline-flex;
  height: 10px;
  align-items: center;
  position: relative;
  top: -1px;
  margin-left: 4px;
}

.mid-container {
  max-width: 1140px;
}

.small-select svg {
  width: 13px;
}

.tile div {
  width: 48px !important;
  height: 48px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.add-step-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8fb;
  z-index: 199;
}

Label,
input[type='radio'] {
  cursor: pointer;
}

.checkbox-label input:checked+.checkbox-span,
input[type="checkbox"]:indeterminate+span {
  background-color: #0e5bff;
  border-color: #0e5bff;
}

.mdi-check::before,
.mdi-minus::before {
  font-weight: 600;
}

.checkbox-span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  width: 14px;
  height: 14px;
  border: 1px solid #b9b9b9;
  border-radius: 2px;
  color: #fff;
}

.bg-t-danger {
  background-color: rgba(244, 106, 106, 0.18);
  color: #F46A6A;
}

.bg-t-success {
  background-color: rgba(52, 195, 143, 0.18);
  ;
  color: #34C38F;
}

.bg-warning-light {
  background-color: rgba(241, 180, 76, 0.18);
  ;
}

.w-18 {
  width: 70px;
}

.py-6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

@media (max-width : 1550px) {
  .nav-tabs-custom .nav-item .nav-link {
    padding: 0.5rem 0.30rem;
  }
}

@media (max-width : 1350px) {
  .nav-tabs-custom .nav-item .nav-link {
    padding: 0.5rem 0.05rem;
  }
}

/* //////////////////////////////////// */
tbody tr {
  height: 56px;
}

tbody td {
  background-color: white !important;
  vertical-align: middle;
}

.table th {
  border-block: 2px solid #dbdbdc;
  background-color: #ebebed;
}

.form-control {
  padding-block: 0.63rem;
}

.css-1fdsijx-ValueContainer {
  padding: 6px 11px !important;
}

.banner-switch {
  transform: scale(1.5);
}

.page-content>div {
  padding: 4rem;
  background-color: white;
}

.page-content>.container-fluid {
  padding: 2rem !important;
}

.upload-file {
  top: 0;
  left: 0;
  height: 100%;
}

.file-upload-bg {
  background-color: #ebebed;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.upload-img>span {
  width: 50px !important;
  height: 50px !important;
}

.file-close>span {
  margin-top: 30px !important;
}

.close-btn:hover {
  transform: scale(1.4);
}

.bx-minus::before {
  font-size: 18px !important;
}

.bx-minus {
  line-height: 0 !important;
}

.drager--column {
  width: 9% !important;
}

.banner--column {
  width: 40%;
}

/* .table-div{
  border: 1px solid #ced4da;
  border-top: none;
} */
.table> :not(caption)>*>* {
  padding-block: 1rem;
}

.drag-pointer {
  display: flex;
}

.text-area {
  border-color: #ced4da !important;
}

.css-b62m3t-container {
  margin-top: 0 !important;
}

.css-likdrv-control {
  border-radius: 4px !important;
  border-color: #ced4da !important;
}

.css-19l6ybu-control:hover,
.css-19l6ybu-control {
  border-color: #ced4da !important;

}

.css-1f225x6-indicatorContainer {
  color: #ced4da !important;
}

.infocard-reorder-table {
  float: right;
}

.disabled-tags {
  background-color: rgb(230, 229, 229);
  padding: 3px 5px;
  border-radius: 4px;
  margin-right: 12px;
}